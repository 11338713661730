/**
* hidden: true,               是否隐藏路由
* path: '/welcome',           路由地址
* component:'Layout',         vue文件路径
* redirect: noredirect,       路由重定向
* name:'welcome',             路由名称父级可不填
* meta : {                    单层级路由父级可不填
   role: ['admin','editor'],    页面权限
   title: 'title'               页面标题
   icon: 'svg-name'             页面图标
   breadcrumb: false,           如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
   keepAlive: false,            页面是否缓存
 }
**/

export default [
  // 首页
  {
    path: '/',
    redirect: '/home',
    component: 'Layout',
    meta: {
      title: '场馆管理',
      icon: 'dashboard',
      role: ['admin', 'addbtn1']
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: '/home/Index',
        meta: {
          title: '场馆管理',
        },
      },
      {
        path: '/siteManagement',
        name: 'siteManagement',
        component: '/home/siteManagement/index',
        meta: {
          title: '场地管理',
        },
        hidden: true,
      },
      {
        path: '/venueReservation',
        name: 'venueReservation',
        component: '/home/venueReservation/index',
        meta: {
          title: '场地预约',
        },
        hidden: true,
      },
      {
        path: 'privateCourses',
        name: 'privateCourses',
        hidden: true,
        meta: {
          title: '场馆课程',
        },
        component: '/courseManage/privateCourses/index',
      },
      {
        path: 'privateActivity',
        name: 'privateActivity',
        meta: {
          title: '场馆活动',
        },
        component: '/activityManage/privateActivity/index',
        hidden: true,
      },
      {
        path: 'privateCourseManageInfo',
        name: 'privateCourseManageInfo',
        meta: {
          title: '课程详情',
        },
        component: '/courseManage/courseManageInfo/index',
        hidden: true,
      },
      {
        path: 'privateActivityInfo',
        name: 'privateActivityInfo',
        meta: {
          title: '活动详情',
        },
        component: '/activityManage/activityInfo/index',
        hidden: true,
      },
    ]
  },
  // 课程管理
  {
    path: '/courseManage',
    redirect: '/courseManage/courseTemplate',
    component: 'Layout',
    meta: {
      title: '课程管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'courseTemplate',
        name: 'courseTemplate',
        meta: {
          title: '课程模板',
        },
        component: '/courseManage/courseTemplate/index',
      },
      // {
      //   path: 'courseList',
      //   name: 'courseList',
      //   meta: {
      //     title: '排课列表',
      //   },
      //   component: '/courseManage/courseList/index',
      // },
      {
        path: 'courseManageInfo',
        name: 'courseManageInfo',
        meta: {
          title: '课程详情',
        },
        component: '/courseManage/courseManageInfo/index',
        hidden: true,
      },
    ]
  },
  // 活动管理
  {
    path: '/activityManage',
    redirect: '/activityManage/activityTemplate',
    component: 'Layout',
    meta: {
      title: '活动管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'activityTemplate',
        name: 'activityTemplate',
        meta: {
          title: '活动模板',
        },
        component: '/activityManage/activityTemplate/index',
      },
      {
        path: 'activityType',
        name: 'activityType',
        meta: {
          title: '活动分类',
        },
        component: '/activityManage/activityType/index',
      },
      {
        path: 'activityInfo',
        name: 'activityInfo',
        meta: {
          title: '活动详情',
        },
        component: '/activityManage/activityInfo/index',
        hidden: true,
      },
    ]
  },
  // 比赛管理
  {
    path: '/matchManage',
    redirect: '/matchManage/matchTemplate',
    component: 'Layout',
    meta: {
      title: '比赛管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'matchTemplate',
        name: 'matchTemplate',
        meta: {
          title: '比赛模板',
        },
        component: '/matchManage/matchTemplate/index',
      },
      {
        path: 'matchInfo',
        name: 'matchInfo',
        meta: {
          title: '比赛详情',
        },
        component: '/matchManage/matchInfo/index',
        hidden: true,
      },
    ]
  },
  // 员工管理
  {
    path: '/employeeManage',
    redirect: '/employeeManage/employeeList',
    component: 'Layout',
    meta: {
      title: '员工管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'employeeList',
        name: 'employeeList',
        meta: {
          title: '员工列表',
        },
        component: '/employeeManage/employeeList/index',
      },
      {
        hidden: true,
        path: 'employeeInfo',
        name: 'employeeInfo',
        meta: {
          title: '员工详情',
        },
        component: '/employeeManage/employeeList/employeeInfo/index',
      },
      {
        path: 'coachList',
        name: 'coachList',
        meta: {
          title: '教练列表',
        },
        component: '/employeeManage/coachList/index',
      },
    ]
  },
  // 客户管理
  {
    path: '/customerManage',
    redirect: '/customerManage/customerList',
    component: 'Layout',
    meta: {
      title: '客户管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'customerList',
        name: 'customerList',
        meta: {
          title: '客户管理',
        },
        component: '/customerManage/index',
      },
      {
        hidden: true,
        path: 'customerInfo',
        name: 'customerInfo',
        meta: {
          title: '客户详情',
        },
        component: '/customerManage/customerInfo/index',
      }
    ]
  },
  // 资源位管理
  {
    path: '/resources',
    redirect: '/resources/banner',
    component: 'Layout',
    meta: {
      title: '资源位管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'banner',
        name: 'banner',
        meta: {
          title: 'Banner位管理',
        },
        component: '/resources/banner/index',
      },
      {
        path: 'kingarea',
        name: 'kingarea',
        meta: {
          title: '金刚位管理',
        },
        component: '/resources/kingarea/index',
      }
    ]
  },
  // 财务管理
  {
    path: '/financeManage',
    redirect: '/financeManage/orderManage',
    component: 'Layout',
    meta: {
      title: '财务管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'orderManage',
        name: 'orderManage',
        meta: {
          title: '订单管理',
        },
        component: '/financeManage/orderManage/index',
      }
    ]
  },
  // 标签管理
  {
    path: '/labelManage',
    redirect: '/labelManage/labelManage',
    component: 'Layout',
    meta: {
      title: '标签管理',
      icon: 'eye-open'
    },
    children: [
      {
        path: 'labelManage',
        name: 'labelManage',
        meta: {
          title: '标签管理',
        },
        component: '/labelManage/index',
      }
    ]
  },
  // 指令权限
  {
    path: '/permission',
    component: 'Layout',
    redirect: '/permission/index',
    meta: {
      title: '指令权限',
      icon: 'eye-open',
    },
    children: [
      {
        path: '/permission/index',
        name: 'permission',
        component: '/permission/Index'
      }
    ],
    hidden: true,
  },
  // 系统管理
  {
    path: "/systemManage",
    component: "Layout",
    redirect: "/systemManage/role",
    meta: {
      title: "系统管理",
      icon: "eye-open",
    },
    children: [
      {
        path: "organization",
        name: "organization",
        component: "/systemManage/organization/index",
        meta: {
          title: "部门管理",
        },
      },
      {
        path: "role",
        name: "role",
        component: "/systemManage/role/index",
        meta: {
          title: "角色管理",
        },
      },
      {
        path: "menu",
        name: "menu",
        component: "/systemManage/menu/index",
        meta: {
          title: "菜单管理",
        },
      },
    ],
  },
]
