<template>
  <!-- 视图区域 -->
  <div>
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'AppMain',
  setup() {}
}
</script>
<style lang="scss" scoped></style>
